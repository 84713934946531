import React, { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react'
import {
  Box,
  Flex,
  HStack,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Stack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { DemandCategory } from 'generated/generated-graphql'
import { Colors } from 'theme/constants'
import { DropdownOption } from '../Dropdown/Dropdown'
import { TextInput, TextInputProps } from '../TextInput'

const removeDiacritics = (str: string) => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

const highlightTextNew = (text: string, query: string) => {
  if (!query) {
    return text
  }

  const normalizedText = removeDiacritics(text.toLowerCase())
  const normalizedQuery = removeDiacritics(query.toLowerCase())

  const parts = []
  let lastIndex = 0

  let index = normalizedText.indexOf(normalizedQuery)
  while (index !== -1) {
    parts.push(text.substring(lastIndex, index))
    parts.push(
      <span style={{ color: '#FF8400', fontWeight: 'bold' }} key={index}>
        {text.substring(index, index + query.length)}
      </span>,
    )
    lastIndex = index + query.length
    index = normalizedText.indexOf(normalizedQuery, lastIndex)
  }

  parts.push(text.substring(lastIndex))
  return <>{parts}</>
}

export interface CategorySearchAutocompleteProps
  extends Omit<TextInputProps, 'onChange' | 'value'> {
  id: string
  categoryOptions: DemandCategory[]
  maxSearchableTerms?: number
  value?: DropdownOption
  onChange?: (newValue: DemandCategory | undefined) => void
}

export const CategorySearchAutocomplete = forwardRef<
  HTMLSelectElement,
  CategorySearchAutocompleteProps
>(
  (
    { id, categoryOptions, maxSearchableTerms = 15, onChange, value, ...rest },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ref: ForwardedRef<any>,
  ) => {
    const internalRef = useRef(null)
    const [searchTerm, setSearchTerm] = useState(value ? value.label : '')
    const { isOpen, onOpen, onClose } = useDisclosure()

    const filteredData = useMemo(() => {
      if (!searchTerm) {
        return categoryOptions
      }

      const normalizedSearchTerm = removeDiacritics(searchTerm.toLowerCase())

      return categoryOptions
        .filter((item) => {
          const normalizedTitle = removeDiacritics(item.name.toLowerCase())
          const hasMatchInText = item.searchableTerms?.some((textItem) =>
            removeDiacritics(textItem.toLowerCase()).includes(normalizedSearchTerm),
          )
          return normalizedTitle.includes(normalizedSearchTerm) || hasMatchInText
        })
        .map((item) => ({
          ...item,
          searchableTerms: [...(item.searchableTerms ? item.searchableTerms : [])].sort(
            (a: string, b: string) => {
              const isAHighlighted = removeDiacritics(a.toLowerCase()).includes(
                normalizedSearchTerm,
              )
              const isBHighlighted = removeDiacritics(b.toLowerCase()).includes(
                normalizedSearchTerm,
              )
              if (isAHighlighted && !isBHighlighted) {
                return -1
              }
              if (!isAHighlighted && isBHighlighted) {
                return 1
              }
              return 0
            },
          ),
        }))
    }, [searchTerm, categoryOptions])

    useEffect(() => {
      if (!searchTerm && value) {
        setSearchTerm(value.label)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
      <Box w="full" mx="auto">
        <Popover isOpen={isOpen} onClose={onClose} autoFocus={false} matchWidth>
          <PopoverAnchor>
            {/* <InputLeftElement pointerEvents="none" w="40px" h="68px">
              <IconSearch size="34px" />
            </InputLeftElement> */}
            <TextInput
              id={id}
              placeholder="Hľadať..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              errorMessageSpacing={0}
              isRequired
              rightElement={
                <HStack>
                  {searchTerm && (
                    <IconX
                      stroke={1.5}
                      onClick={() => {
                        setSearchTerm('')
                        onOpen()
                        onChange?.(undefined)
                      }}
                    />
                  )}
                  <IconChevronDown
                    size="20px"
                    color={Colors.brand.blue100}
                    onClick={() => {
                      onOpen()
                    }}
                  />
                </HStack>
              }
              ref={ref || internalRef}
              // h="68px"
              // py="24px"
              // px="16px"
              // bg="white"
              // border="none"
              // boxShadow="primary"
              onClick={onOpen}
              {...rest}
            />
          </PopoverAnchor>
          <PopoverContent w="full" border="none" maxH="500px" overflowY="auto" bg="transparent">
            <VStack w="full" spacing="8px">
              {filteredData.length > 0 ? (
                filteredData.map((item) => (
                  <Flex
                    key={item.id}
                    w="full"
                    py="20px"
                    px="24px"
                    border="1px solid #CCCCCC"
                    borderRadius="12px"
                    _hover={{ bg: 'brand.blue10', cursor: 'pointer' }}
                    _active={{ bg: 'brand.blue100' }}
                    bg="white"
                    onClick={() => {
                      onChange?.(item)
                      onClose()
                      setSearchTerm(item.name)
                    }}
                  >
                    <Stack w="full" spacing={1}>
                      <Text fontFamily="DM Sans Semibold">
                        {highlightTextNew(item.name, searchTerm)}
                      </Text>
                      <Flex w="full" wrap="wrap" gap="8px" overflow="hidden">
                        {item.searchableTerms
                          ?.slice(0, maxSearchableTerms)
                          .map((textItem: string, index) => (
                            <Text
                              // eslint-disable-next-line react/no-array-index-key
                              key={textItem + index}
                              noOfLines={3}
                              color="brand.darkBlue80"
                              style={{ overflowWrap: 'normal' }}
                              fontSize="13px"
                            >
                              {highlightTextNew(textItem, searchTerm)}
                              {index + 1 <
                                (item.searchableTerms?.slice(0, maxSearchableTerms).length ||
                                  maxSearchableTerms) && ' | '}
                            </Text>
                          ))}
                      </Flex>
                    </Stack>
                  </Flex>
                ))
              ) : (
                <Flex>Žiadne výsledky.</Flex>
              )}
            </VStack>
          </PopoverContent>
        </Popover>
      </Box>
    )
  },
)
