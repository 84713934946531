import {
  AspectRatio,
  Button,
  Circle,
  Divider,
  Flex,
  StackProps,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { IconArrowNarrowRight } from '@tabler/icons-react'
import { addDays, format } from 'date-fns'
import { File } from 'generated/generated-graphql'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { getFormattedDate } from 'utils/date'
import { getLocaleFormattedPrice } from 'utils/price'

export const DirectOfferCard = ({
  id,
  name,
  titleImage,
  unitPrice,
  unit,
  validTill = format(addDays(new Date(), 30), 'yyyy-MM-dd'),
  // contractorName,
  condition,
  numberOfNewOrders,
  linkTo,
  style,
}: {
  id: number
  name: string
  titleImage?: File
  unitPrice: number
  unit: string
  validTill?: string
  // contractorName?: string | null
  condition?: string
  numberOfNewOrders?: number | null
  linkTo: string
  style?: StackProps
}) => {
  const { push } = useRouter()
  return (
    <VStack
      minW="full"
      maxW={{ base: 'full', md: '373px' }}
      position="relative"
      w="full"
      spacing="16px"
      align="start"
      borderColor="#CCCCCC"
      bg="white"
      borderRadius="12px"
      borderWidth="1px"
      py="12px"
      px="16px"
      boxShadow="sm"
      h="full"
      onClick={() => {
        void push(linkTo)
      }}
      {...style}
    >
      {numberOfNewOrders && numberOfNewOrders !== 0 && (
        <Circle size="24px" bg="brand.blue100" position="absolute" top={-2} right={-4}>
          <Tooltip
            label={`${numberOfNewOrders} ${
              numberOfNewOrders === 1 ? 'nová objednávka' : 'nové objednávky'
            }`}
          >
            <Text color="white" fontFamily="DM Mono">
              {numberOfNewOrders}
            </Text>
          </Tooltip>
        </Circle>
      )}

      <Flex
        position="relative"
        w="full"
        h="299px"
        bg="brand.fogGray"
        borderRadius="12px"
        overflow="hidden"
      >
        {titleImage?.fileUrl && (
          <AspectRatio w="full" h="299px" ratio={1 / 1} position="relative">
            <Image alt={titleImage.filename} src={titleImage.fileUrl} fill />
          </AspectRatio>
        )}
      </Flex>
      <Text w="full" align="start" fontSize="16px" lineHeight="150%" textTransform="uppercase">
        {name}
      </Text>
      <Divider />
      <Text fontFamily="Montserrat Semibold" fontSize="24px" lineHeight="150%">
        Cena:{' '}
        <span
          style={{
            fontFamily: 'Montserrat Semibold',
            fontSize: '24px',
            lineHeight: '150%',
            color: '#007FFF',
          }}
        >
          {`${getLocaleFormattedPrice(unitPrice)} /${unit}`}
        </span>
      </Text>
      <VStack spacing={0} align="start">
        {condition && (
          <Text w="full" align="start" noOfLines={1}>
            {condition}
          </Text>
        )}
        <Text fontFamily="Montserrat Semibold" fontSize="14px" lineHeight="150%">
          Ponuka platná do:{' '}
          <span
            style={{
              fontFamily: 'Montserrat Semibold',
              fontSize: '14px',
              lineHeight: '150%',
              color: '#007FFF',
            }}
          >
            {getFormattedDate(validTill)}
          </span>
        </Text>
        {/* {contractorName && (
          <Text fontFamily="Montserrat Semibold" fontSize="14px" lineHeight="150%">
            {contractorName}
          </Text>
        )} */}
      </VStack>
      {id && (
        <Flex w="full" justify="end">
          <Link href={linkTo}>
            <Button rightIcon={<IconArrowNarrowRight stroke={1.5} />} size="md" variant="link">
              Detail ponuky
            </Button>
          </Link>
        </Flex>
      )}
    </VStack>
  )
}
