import { useEffect } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { animate, motion, useMotionValue, useTransform } from 'framer-motion'

const CursorVariants = {
  blinking: {
    opacity: [0, 0, 1, 1],
    transition: {
      duration: 1,
      repeat: Infinity,
      repeatDelay: 0,
      ease: 'linear',
      times: [0, 0.5, 0.5, 1],
    },
  },
}

export const CursorBlinker = () => (
  <motion.div
    variants={CursorVariants}
    animate="blinking"
    style={{
      display: 'inline-block',
      height: '5px',
      width: '1px',
      translateY: '1',
      background: 'red',
    }}
  />
)

export const TextAnim = () => {
  const baseText = 'Dear Hiring Manager, '
  const count = useMotionValue(0)
  const rounded = useTransform(count, (latest) => Math.round(latest))
  const displayText = useTransform(rounded, (latest) => baseText.slice(0, latest))
  useEffect(() => {
    const controls = animate(count, baseText.length, {
      type: 'tween', // Not really needed because adding a duration will force "tween"
      duration: 1,
      ease: 'easeInOut',
    })
    return controls.stop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <span className="">
      <motion.span>{displayText}</motion.span>
      <CursorBlinker />
    </span>
  )
}

export const RedoAnimText = () => {
  const textIndex = useMotionValue(0)
  const texts = [
    'Stavbu rodinného domu ...',
    'Rekonštrukciu svojho bytu ...',
    'Bezbariérové schodisko ...',
    'Pokládku podlahy ...',
    'Maľovanie celého bytu...',
  ]

  const baseText = useTransform(textIndex, (latest) => texts[latest] || '')
  const count = useMotionValue(0)
  const rounded = useTransform(count, (latest) => Math.round(latest))
  const displayText = useTransform(rounded, (latest) => baseText.get().slice(0, latest))
  const updatedThisRound = useMotionValue(true)

  useEffect(() => {
    animate(count, 60, {
      type: 'tween',
      duration: 1,
      delay: 0.5,
      ease: 'easeIn',
      repeat: Infinity,
      repeatType: 'reverse',
      repeatDelay: 1,
      onUpdate(latest) {
        if (updatedThisRound.get() === true && latest > 0) {
          updatedThisRound.set(false)
        } else if (updatedThisRound.get() === false && latest === 0) {
          if (textIndex.get() === texts.length - 1) {
            textIndex.set(0)
          } else {
            textIndex.set(textIndex.get() + 1)
          }
          updatedThisRound.set(true)
        }
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Text
      as={motion.span}
      w={{ base: 'auto', md: '100%' }}
      style={{
        display: 'inline',
        fontFamily: 'Montserrat',
        fontSize: 'inherit',
        lineHeight: '150%',
        color: '#3177FF',
      }}
    >
      {displayText as unknown as string}
    </Text>
  )
}

export const LookingForAnimatedText = () => (
  <Flex
    w="full"
    justify="center"
    // pb="48px"
    wrap="nowrap"
    gap="16px"
    fontSize={{ base: '24px', lg: '32px' }}
    direction={{ base: 'column', md: 'row' }}
  >
    <Text
      as={motion.h2}
      initial={{
        opacity: 0,
        y: 20,
      }}
      animate={{
        opacity: 1,
        y: [20, -5, 0],
      }}
      // @ts-expect-error
      transition={{
        duration: 1,
        ease: [0.4, 0.0, 0.2, 1],
      }}
      fontFamily="Montserrat"
      fontSize="inherit"
      lineHeight="150%"
      textAlign={{ base: 'center', md: 'end' }}
      width={{ base: 'full', md: '50%' }}
    >
      Hľadám dodávateľa na
    </Text>
    <Flex
      justify={{ base: 'center', md: 'start' }}
      w={{ base: 'auto', md: '50%' }}
      h="30px"
      px={{ base: '16px', md: 0 }}
    >
      <RedoAnimText />
    </Flex>
  </Flex>
)
