import { Divider, VStack, Wrap, WrapItem } from '@chakra-ui/react'
import { Badge } from './Badge'

export const CategoriesWrapper = ({
  categories,
  currentCategory,
  onCategorySelection,
}: {
  currentCategory?: string
  categories: {
    items: {
      uuid: string
      name: string
      slug: string
    }[]
  }
  onCategorySelection: (selectedCategory: string) => void
}) => (
  <VStack w="full" spacing="16px">
    <Divider />
    <Wrap w="full" justify="center" spacing="16px">
      <WrapItem>
        <Badge
          label="Všetky"
          isActive={currentCategory === 'Všetky'}
          onClick={() => {
            if (currentCategory !== 'Všetky') {
              onCategorySelection('Všetky')
            }
          }}
        />
      </WrapItem>
      {categories.items?.map(({ name, slug, uuid }) => (
        <WrapItem key={slug}>
          <Badge
            label={name}
            isActive={currentCategory === slug}
            onClick={() => {
              if (currentCategory !== slug) {
                onCategorySelection(uuid)
              }
            }}
          />
        </WrapItem>
      ))}
    </Wrap>
    <Divider />
  </VStack>
)
