import { ReactElement } from 'react'
import { Box, Flex, FlexProps, HStack, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import { Colors } from 'theme/constants'

interface GalleryButtonProps extends FlexProps {
  title: string
  highlight: string | ReactElement
  isSelected: boolean
  hasMotionOnMobile?: boolean
}
const UnderlineMotionDiv = () => (
  <motion.div
    layoutId="underline"
    transition={{ duration: 0.5 }}
    style={{
      backgroundColor: Colors.brand.blue100,
      width: '5px',
      height: '100%',
      borderRadius: 'full',
      position: 'absolute',
      zIndex: 0,
      left: '-27px',
    }}
  />
)

export const BenefitsButton = ({
  title,
  highlight,
  isSelected,
  hasMotionOnMobile,
  ...rest
}: GalleryButtonProps) => {
  const isOnMobile = useIsOnMobile()

  return (
    <Flex
      as={motion.div}
      display="flex"
      key={title}
      w="full"
      maxH="fit-content"
      maxW={{ base: 'full', lg: '600px' }}
      borderRadius="12px"
      alignItems="center"
      position="relative"
      p="24px"
      cursor="pointer"
      _hover={{
        borderColor: 'brand.blue100',
        boxShadow: 'medium',
      }}
      initial={{ backgroundColor: 'white' }}
      exit={{ backgroundColor: 'white' }}
      transition={{ type: 'ease-in', ease: 'linear' }}
      {...rest}
    >
      {(hasMotionOnMobile ? true : !isOnMobile) && isSelected && <UnderlineMotionDiv />}
      {isSelected && (
        <Box
          position="absolute"
          w="full"
          top={0}
          left={0}
          h="100%"
          borderRadius="12px"
          boxShadow="primary"
          zIndex={1}
        />
      )}

      <VStack zIndex={2} spacing="8px" align="start">
        <HStack>
          <Text
            fontFamily="Montserrat Semibold"
            fontSize="20px"
            lineHeight="150%"
            {...(isSelected && { color: 'brand.blue100' })}
          >
            {title}
          </Text>
        </HStack>
        {isSelected && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'fit-content' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ type: 'tween' }}
            style={{ overflow: 'clip', width: '100%', fontSize: '16px' }}
          >
            {highlight === typeof 'string' ? <Text fontSize="16px">{highlight}</Text> : highlight}
          </motion.div>
        )}
      </VStack>
    </Flex>
  )
}
