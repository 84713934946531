import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { NextPageWithLayout } from 'types/next'
import { useAuth } from 'providers/UserProvider'

export const withRedirect = () => (Component: NextPageWithLayout) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const WrappedComponent = (props: any) => {
    const { userData } = useAuth()
    const router = useRouter()

    useEffect(() => {
      if (!userData) {
        return
      }
      if (router.query?.returnTo) {
        void router.replace(String(router.query?.returnTo))
      }
    }, [router, userData])

    return <Component {...props} />
  }
  WrappedComponent.getLayout = Component.getLayout
  return WrappedComponent
}
