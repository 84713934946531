import { Box, Button, Center, Container, Flex, Text, VStack } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { ColorfulText } from './ColorfulText'
import { HeroSectionChatBuble } from './HeroSectionChatBuble'

export const HeroSection = () => (
  <Container maxW="container.xl" p={0} centerContent>
    <Flex
      w="full"
      direction={{ base: 'column', md: 'row' }}
      pt="64px"
      // pb={{ base: '32px', md: '64px' }}
      align="center"
      justify="space-between"
      overflow="hidden"
      px={{ base: 0, md: '24px' }}
      position="relative"
    >
      <VStack
        align={{ base: 'center', lg: 'start' }}
        spacing={{ base: '32px', lg: '24px' }}
        pb={{ base: '32px', md: 0 }}
      >
        <Text
          as={motion.h1}
          initial={{
            opacity: 0,
            y: 20,
          }}
          animate={{
            opacity: 1,
            y: [20, -5, 0],
          }}
          // @ts-expect-error
          transition={{
            duration: 2,
            ease: [0.4, 0.0, 0.2, 1],
          }}
          fontFamily="Montserrat Semibold"
          fontSize={{ base: '24px', lg: '40px' }}
          lineHeight="120%"
          letterSpacing="-1.5%"
          w="full"
          maxW={{ base: '380px', lg: '600px' }}
          textAlign={{ base: 'center', lg: 'start' }}
        >
          Zadaj dopyt <ColorfulText text="pre svoj projekt" />
          rýchlo a výhodne!
        </Text>
        <Text
          as="h2"
          fontSize={{ base: '14px', lg: '20px' }}
          lineHeight="150%"
          maxW={{ base: '380px', lg: '400px' }}
          textAlign={{ base: 'center', lg: 'start' }}
        >
          Získaj{' '}
          <span style={{ fontSize: 'inherit', fontFamily: 'Dm Sans Semibold' }}>zadarmo</span>{' '}
          niekoľko nezávislých ponúk od dodávateľov stavebných prác a súvisiacich služieb.
        </Text>
        <Link href={Route.Public.CreateDemand()}>
          <Button w="240px" display={{ base: 'block', lg: 'none' }}>
            Vytvoriť dopyt
          </Button>
        </Link>
      </VStack>
      <Box h="500px" display={{ base: 'block', md: 'none' }} />
      <Box
        w={{ base: 'fit-content', xl: 'full' }}
        maxW="600px"
        right={{ base: '-20px', md: 0 }}
        bottom={0}
        position={{ base: 'absolute', md: 'relative' }}
      >
        <Center w="full" position="relative">
          <VStack
            maxW={{ base: '190px', lg: 'fit-content' }}
            align="end"
            spacing="16px"
            position="absolute"
            top="180px"
            left={{ base: '-32px', lg: '-64px', xl: '0px' }}
          >
            <HeroSectionChatBuble
              text={
                <>
                  Potrebovala by som <strong>vymaľovať byt</strong>
                </>
              }
            />
            <HeroSectionChatBuble text="4i, rozloha 62m2, Košice" />
          </VStack>
          <VStack
            maxW={{ base: '190px', lg: 'fit-content' }}
            align="start"
            spacing="16px"
            position="absolute"
            top="340px"
            left={{ base: '148px', md: '50%' }}
            zIndex={100}
          >
            <HeroSectionChatBuble bgColor="gray.100" text="Ponuka bola vytvorená" />
            <HeroSectionChatBuble bgColor="gray.100" text="Môžeme prísť zajtra?" />
          </VStack>
          <Image src="/img/woman.png" width={356} height={500} alt="" priority />
          <Box
            w="full"
            h="180px"
            position="absolute"
            bottom={0}
            bgGradient="linear(to-t, #FFFFFF, transparent)"
          />
        </Center>
      </Box>
    </Flex>
  </Container>
)
