import { useMemo, useState } from 'react'
import { Button, Stack, Text, VStack } from '@chakra-ui/react'
import { IconSearch } from '@tabler/icons-react'
import { DemandCategory, DemandListingType } from 'generated/generated-graphql'
import { useRouter } from 'next/router'
import { Colors } from 'theme/constants'
import { Route } from 'constants/common/routes'
import { CategorySearchAutocomplete } from 'components/inputs/CategorySearchAutocomplete/CategorySearchAutocomplete'
import { BaseCard } from 'components/ui/BaseCard'
import { useAuth } from 'providers/UserProvider'
import { ContainerWithTag } from '../../components/landingPage/ContainerWithTag'

export const CreateDemandSection = ({ listingTypes }: { listingTypes: DemandListingType[] }) => {
  const router = useRouter()
  const { userData } = useAuth()
  const [selectedListingTypeId, setSelectedListingTypeId] = useState(1)
  const [selectedCategory, setSelectedCategory] = useState<DemandCategory | null>(null)

  const categoryOptions = useMemo(() => {
    const selectedListingType = listingTypes.find(({ id }) => id === selectedListingTypeId)

    return selectedListingType?.demandCategories?.filter((category) => category.parentCategoryId)
  }, [listingTypes, selectedListingTypeId])

  return (
    <ContainerWithTag title="Vytvorenie dopytu">
      <VStack w="full" px="24px" spacing="48px">
        <VStack w="full" align="start" spacing="24px">
          <Text fontFamily="DM Sans Medium" fontSize="24px" color="brand.blue100">
            Vyberte typ vášho dopytu
          </Text>
          <Stack w="full" spacing="32px" direction="row">
            <BaseCard
              w="full"
              py="20px"
              px="24px"
              role="group"
              _hover={{ bgColor: 'brand.blue100', color: 'white' }}
              onClick={() => setSelectedListingTypeId(1)}
              {...(selectedListingTypeId === 1 && { bgColor: 'brand.blue100', color: 'white' })}
            >
              <VStack align="start" spacing="10px">
                <Text
                  fontFamily="DM Sans Medium"
                  fontSize="16px"
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 1 && { color: 'white' })}
                >
                  Súkromný dopyt
                </Text>
                <Text
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 1 && { color: 'white' })}
                >
                  Dopyt pre súkromné účely
                </Text>
              </VStack>
            </BaseCard>
            <BaseCard
              w="full"
              py="20px"
              px="24px"
              role="group"
              _hover={{ bgColor: 'brand.blue100', color: 'white' }}
              onClick={() => setSelectedListingTypeId(2)}
              {...(selectedListingTypeId === 2 && { bgColor: 'brand.blue100', color: 'white' })}
            >
              <VStack align="start" spacing="10px">
                <Text
                  fontFamily="DM Sans Medium"
                  fontSize="16px"
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 2 && { color: 'white' })}
                >
                  Firemný dopyt
                </Text>
                <Text
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 2 && { color: 'white' })}
                >
                  Dopyt pre firemné účely
                </Text>
              </VStack>
            </BaseCard>
            <BaseCard
              w="full"
              py="20px"
              px="24px"
              role="group"
              _hover={{ bgColor: 'brand.blue100', color: 'white' }}
              onClick={() => setSelectedListingTypeId(3)}
              {...(selectedListingTypeId === 3 && { bgColor: 'brand.blue100', color: 'white' })}
            >
              <VStack align="start" spacing="10px">
                <Text
                  fontFamily="DM Sans Medium"
                  fontSize="16px"
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 3 && { color: 'white' })}
                >
                  Bytový dom
                </Text>
                <Text
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 3 && { color: 'white' })}
                >
                  Pre potreby bytového domu
                </Text>
              </VStack>
            </BaseCard>
            <BaseCard
              w="full"
              py="20px"
              px="24px"
              role="group"
              _hover={{ bgColor: 'brand.blue100', color: 'white' }}
              onClick={() => setSelectedListingTypeId(4)}
              {...(selectedListingTypeId === 4 && { bgColor: 'brand.blue100', color: 'white' })}
            >
              <VStack align="start" spacing="10px">
                <Text
                  fontFamily="DM Sans Medium"
                  fontSize="16px"
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 4 && { color: 'white' })}
                >
                  Havarijná služba
                </Text>
                <Text
                  _groupHover={{ color: 'white' }}
                  {...(selectedListingTypeId === 4 && { color: 'white' })}
                >
                  Havarijný zásah, poruchy
                </Text>
              </VStack>
            </BaseCard>
          </Stack>
        </VStack>

        <VStack w="680px" align="start" spacing="24px">
          <Text fontFamily="DM Sans Medium" fontSize="24px" color="brand.blue100">
            Nájdite svojho dodávateľa na
          </Text>
          {categoryOptions && (
            <CategorySearchAutocomplete
              id="category"
              variant="categorySearch"
              categoryOptions={categoryOptions}
              onChange={(newValue) => {
                setSelectedCategory(newValue ?? null)
              }}
              leftAddon={
                <IconSearch size="34px" stroke={1.5} color={Colors.brand.blue100} fill="#BDD4FF" />
              }
            />
          )}
        </VStack>

        <Button
          w="360px"
          onClick={() => {
            if (selectedCategory && selectedListingTypeId) {
              void router.push(
                userData
                  ? Route.App.Demand.CreateDemand({
                      categoryId: selectedCategory.id,
                      listingTypeId: selectedListingTypeId,
                    })
                  : Route.Public.CreateDemand({
                      categoryId: selectedCategory.id,
                      listingTypeId: selectedListingTypeId,
                    }),
              )
              return
            }
            if (selectedListingTypeId) {
              void router.push(
                userData
                  ? Route.App.Demand.CreateDemand({
                      listingTypeId: selectedListingTypeId,
                    })
                  : Route.Public.CreateDemand({
                      listingTypeId: selectedListingTypeId,
                    }),
              )
              return
            }
            void router.push(
              userData ? Route.App.Demand.CreateDemand() : Route.Public.CreateDemand(),
            )
          }}
        >
          Vytvoriť dopyt
        </Button>
      </VStack>
      <Text as="h2" maxW="820px" py="48px" fontFamily="Montserrat" align="center" fontSize="16px">
        Zadajte dopyt na kvalifikované stavebné práce a všetky súvisiace služby. Ak staviate,
        renovujete, opravujete, alebo už zariaďujete svoju nehnuteľnosť, tak ste tu správne!
      </Text>
    </ContainerWithTag>
  )
}
