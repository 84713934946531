import { ReactNode } from 'react'
import { Flex, FlexProps } from '@chakra-ui/react'

interface CardProps extends FlexProps {
  children: ReactNode
}

export const BaseCard = ({ children, ...rest }: CardProps) => (
  <Flex
    w="full"
    py="32px"
    px="48px"
    borderRadius="12px"
    borderWidth="1px"
    borderColor="#CCCCCC"
    boxShadow="primary"
    bg="white"
    {...rest}
  >
    {children}
  </Flex>
)
