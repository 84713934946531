import { ReactElement } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'

export const HeroSectionChatBuble = ({
  text,
  bgColor = '#BDD4FF',
}: {
  text: string | ReactElement
  bgColor?: string
}) => (
  <Flex
    as={motion.div}
    initial={{
      opacity: 0,
      y: 20,
    }}
    animate={{
      opacity: 1,
      y: [20, -5, 0],
    }}
    // @ts-expect-error
    transition={{
      ease: [0.4, 0.0, 0.2, 1],
    }}
    transitionDelay="3 s"
    maxW={{ base: '190px', xl: '220px' }}
    bg={bgColor}
    px={{ base: '12px', xl: '24px' }}
    py={{ base: '8px', xl: '16px' }}
    borderRadius="24px"
    borderBottomRightRadius={0}
    boxShadow="md"
  >
    <Text fontFamily="Montserrat" fontSize={{ base: '14px', xl: '16px' }}>
      {text}
    </Text>
  </Flex>
)
