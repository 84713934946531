import { Avatar, AvatarProps, Flex, Heading } from '@chakra-ui/react'

export interface UserAvatarProps {
  isWithName?: boolean
  maxNameWidth?: AvatarProps['maxW']
  src?: string | null
  name?: string | null
  email: string
  size?: string
  backgroundColor?: AvatarProps['backgroundColor']
  otherAvatarProps?: AvatarProps
  hasShadow?: boolean
  hasBlueOutline?: boolean
}

export const UserAvatar = ({
  isWithName,
  maxNameWidth,
  src,
  name,
  email,
  size = 'md',
  backgroundColor = 'brand.darkBlue10',
  otherAvatarProps,
  hasShadow,
  hasBlueOutline = false,
}: UserAvatarProps) => (
  <Flex align="center" gap="16px">
    {isWithName && (
      <Heading size="sm" fontFamily="DM Sans SemiBold" maxW={maxNameWidth} isTruncated>
        {name ?? email}
      </Heading>
    )}
    <Avatar
      size={size}
      {...(src && { src })}
      name={name || email}
      getInitials={() => (name && name[0] ? name[0] : email[0] || '')}
      backgroundColor={backgroundColor}
      color="brand.darkBlueGray"
      {...otherAvatarProps}
      {...(hasBlueOutline && {
        outline: '2px solid',
        outlineColor: 'brand.blue100',
      })}
      {...(hasShadow && { shadow: 'base' })}
    />
  </Flex>
)
