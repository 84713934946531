import React, { useState } from 'react'
import { Button, Flex, VStack } from '@chakra-ui/react'
import { DemandStatus, useDemandListQuery } from 'generated/generated-graphql'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { DemandListTable } from 'routes/DemandList/DemandListTable'
import { ContainerWithTag } from '../../components/landingPage/ContainerWithTag'

export const DemandListPreview = () => {
  const isOnMobile = useIsOnMobile()
  const pageLimit = isOnMobile ? 3 : 6
  const [demandListingTypeId, setDemandListingTypeId] = useState<number | null>(null)
  const [currentOffset, setCurrentOffset] = useState(0)
  const {
    data: demandListData,
    loading,
    fetchMore: fetchMoreDemands,
  } = useDemandListQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        pagination: { limit: pageLimit, offset: currentOffset },
        filter: { listingTypeId: demandListingTypeId, status: DemandStatus.Published },
      },
    },
  })

  return (
    <>
      <ContainerWithTag title="Najnovšie dopyty">
        <VStack spacing={{ base: '32px', lg: '24px' }} align="start" pb="64px" w="full">
          <Flex
            w="full"
            wrap="wrap"
            gap={{ base: '8px', md: '16px' }}
            justify={{ base: 'center', md: 'start' }}
          >
            <Button
              w={{ base: 'auto', md: '120px' }}
              p="8px"
              variant="dashboardStatusFilter"
              isActive={demandListingTypeId === 1}
              onClick={() => {
                setDemandListingTypeId(1)
              }}
              border="1px #CCCCCC solid"
              bgColor="white"
              shadow="primary"
            >
              Všetky dopyty
            </Button>
            <Button
              w={{ base: 'auto', md: '120px' }}
              p="8px"
              variant="dashboardStatusFilter"
              border="1px #CCCCCC solid"
              bgColor="white"
              shadow="primary"
              isActive={demandListingTypeId === null}
              onClick={() => {
                setDemandListingTypeId(null)
              }}
            >
              Súkromné dopyty
            </Button>
            <Button
              w={{ base: 'auto', md: '120px' }}
              p="8px"
              variant="dashboardStatusFilter"
              border="1px #CCCCCC solid"
              bgColor="white"
              shadow="primary"
              isActive={demandListingTypeId === 2}
              onClick={() => {
                setDemandListingTypeId(2)
              }}
            >
              Firemné dopyty
            </Button>
            <Button
              w={{ base: 'auto', md: '120px' }}
              p="8px"
              variant="dashboardStatusFilter"
              border="1px #CCCCCC solid"
              bgColor="white"
              shadow="primary"
              isActive={demandListingTypeId === 3}
              onClick={() => {
                setDemandListingTypeId(3)
              }}
            >
              Bytové domy
            </Button>
            <Button
              w={{ base: 'auto', md: '120px' }}
              p="8px"
              variant="dashboardStatusFilter"
              border="1px #CCCCCC solid"
              bgColor="white"
              shadow="primary"
              isActive={demandListingTypeId === 4}
              onClick={() => {
                setDemandListingTypeId(4)
              }}
            >
              Havarijné služby
            </Button>
          </Flex>
          <DemandListTable
            isPublicRoute
            pageLimit={pageLimit}
            hasPagination={false}
            isLoading={loading}
            currentOffset={currentOffset}
            headerStyles={{ templateColumns: '2fr 2fr 220px 120px' }}
            rowStyles={{
              templateColumns: '2fr 2fr 220px 120px',
              backgroundColor: 'white',
              shadow: 'primary',
            }}
            onOffsetChange={(value) => {
              void fetchMoreDemands({
                variables: {
                  input: {
                    pagination: { limit: pageLimit, offset: currentOffset },
                  },
                },
              }).then(() => setCurrentOffset(value))
            }}
            demandListData={demandListData}
            activeListingTypeId={demandListingTypeId ?? undefined}
          />
          <Flex w="full" justifyContent="center">
            <Link href={Route.Public.DemandList()}>
              <Button variant="link">Zobraziť viac dopytov</Button>
            </Link>
          </Flex>
        </VStack>
      </ContainerWithTag>
    </>
  )
}
