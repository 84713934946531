'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { Colors } from 'theme/constants'

export function ColorfulText({ text }: { text: string }) {
  const colors = [Colors.brand.blue100]

  const [currentColors, setCurrentColors] = React.useState(colors)
  const [count, setCount] = React.useState(0)

  React.useEffect(() => {
    const shuffled = [...colors].sort(() => Math.random() - 0.5)
    setCurrentColors(shuffled)
    setCount((prev) => prev + 1)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return text.split('').map((char, index) => (
    <motion.span
      // eslint-disable-next-line react/no-array-index-key
      key={`${char}-${count}-${index}`}
      initial={{
        y: 0,
      }}
      animate={{
        color: currentColors[index % currentColors.length],
        y: [0, -3, 0],
        scale: [1, 1.01, 1],
        filter: ['blur(0px)', `blur(5px)`, 'blur(0px)'],
        opacity: [1, 0.8, 1],
      }}
      transition={{
        duration: 0.3,
        delay: index * 0.05,
      }}
      style={{
        display: 'inline-block',
        fontSize: 'inherit',
        fontFamily: 'inherit',
        lineHeight: 'inherit',
        whiteSpace: 'pre',
      }}
    >
      {char}
    </motion.span>
  ))
}
