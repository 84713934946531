import React, { useCallback } from 'react'
import { Box, Flex } from '@chakra-ui/react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import useEmblaCarousel from 'embla-carousel-react'
import { DirectOffer } from 'generated/generated-graphql'
import { Route } from 'constants/common/routes'
import { DirectOfferCard } from 'components/directOffer/DirectOfferCard'

export const DirectOffersSlider = ({ directOffers }: { directOffers: DirectOffer[] }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    { slidesToScroll: 'auto', containScroll: 'trimSnaps', dragFree: true },
    [],
  )
  const hasArrows = directOffers.length > 2

  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  return (
    <Flex w="full" gap="8px" align="center">
      {hasArrows && (
        <IconChevronLeft
          onClick={() => {
            scrollPrev()
          }}
        />
      )}
      <Box w="full" ref={emblaRef} overflow="hidden">
        <Flex w="full" justify="space-between" gap="16px">
          {directOffers?.map(({ id, name, unit, unitPrice, validTill, images, condition }) => (
            <Box key={id} w={{ base: 'full', md: '360px' }}>
              <DirectOfferCard
                key={id}
                id={id}
                name={name}
                unit={unit}
                unitPrice={unitPrice}
                validTill={validTill}
                titleImage={images?.[0]}
                condition={condition}
                linkTo={Route.App.DirectOfferDetail({ id })}
                style={{ w: '360px' }}
              />
            </Box>
          ))}
        </Flex>
      </Box>
      {hasArrows && (
        <IconChevronRight
          onClick={() => {
            scrollNext()
          }}
        />
      )}
    </Flex>
  )
}
