import { useState } from 'react'
import {
  AspectRatio,
  Box,
  BoxProps,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { AnimateSharedLayout } from 'framer-motion'
import Image from 'next/image'
import { Colors } from 'theme/constants'
import { BenefitsButton } from './BenefitsButton'

export const LandingPageCustomerBenefits = [
  {
    src: '/img/test-mix.png',
    title: 'Šetrite čas a zrýchlite získavanie ponúk',
    text: 'Zabudnite na zdĺhavé hľadanie remeselníkov a obvolávanie firiem. S našou platformou stačí zadať dopyt raz a oslovíte tým desiatky potenciálnych dodávateľov naraz. Ušetríte množstvo času a energie, ktoré môžete venovať dôležitejším veciam.',
  },
  {
    src: '/landingPage/snapshots/test2.png',
    title: 'Porovnajte ceny a kvalitu ponúk',
    text: 'Dopyty sú prístupné širokej sieti dodávateľov, ktorí medzi sebou súťažia o zákazky. Vďaka tomu môžete získať kvalitné cenové ponuky a vybrať tú, ktorá zodpovedá vašim potrebám a rozpočtu. Ponuky sú prehľadne zoradené a viete ich porovnávať podľa ceny, termínu realizácie, hodnotenia dodávateľa a ďalších kritérií.',
  },
  {
    src: '/landingPage/snapshots/request_demo_example.png',
    title: 'Majte kontrolu nad svojim projektom',
    text: 'Všetky ponuky a komunikáciu s dodávateľmi máte na jednom mieste, kde ich viete porovnávať, komunikovať s dodávateľmi, dohadovať detaily a sledovať priebeh projektu. Máte plnú kontrolu nad celým procesom, čím výrazne zvyšujete úspešnosť projektu.',
  },
  {
    src: '/landingPage/snapshots/bids_table_example.png',
    title: 'Prístupnosť dopytu širokej škále profesií',
    text: 'Vytvorením dopytu na našej platforme viete efektívne a hlavne naraz osloviť širokú škálu odborníkov - od projektantov, architektov, odborníkov na interiérový dizajn, montáž nábytku či exteriérové práce (ako záhrady, ploty a pod.), ale aj malých remeselníkov či väčšie stavebné firmy pre komplexné projekty. Získate lepšie riešenia a cenové ponuky, ktoré môžu znížiť vaše náklady a časovú náročnosť.',
  },
  {
    src: '/landingPage/snapshots/bids_table_example.png',
    title: 'Záruka transparentnosti a dôveryhodnosti',
    text: 'Zárukou transparentnosti je úplne otvorený trh platformy, kde všetci dodávatelia majú rovnakú príležitosť reagovať na dopyty svojimi ponukami, čo podporuje zdravú konkurenciu. Hodnotenia od predchádzajúcich zákazníkov a dostupnosť firemných informácií napomáhajú pri výbere vhodného dodávateľa.',
  },
] as const

export const BenefitsSection = ({ ...rest }: BoxProps) => {
  const [activeItemIndex, setActiveItemIndex] = useState(0)

  // const activeImage = LandingPageCustomerBenefits[activeItemIndex]

  return (
    <Box w="full" px="16px" bg="white" {...rest}>
      <Container maxW="container.xl" p={0} centerContent>
        <VStack w="full" align="start" spacing="48px">
          <Text as="h2" fontSize="32px" fontFamily="Montserrat Semibold">
            Prečo{' '}
            <span style={{ color: Colors.brand.blue100, fontFamily: 'Montserrat Semibold' }}>
              Zadaj dopyt
            </span>{' '}
            ?{' '}
          </Text>
          <Stack w="full" h="600px" direction="row" align="start">
            <Flex
              w="full"
              h="full"
              maxH={{ base: 'auto', lg: '596px' }}
              maxW={{ base: 'full', lg: '596px' }}
              justify="center"
              align="center"
              mr={{ base: 0, lg: '40px' }}
              mb={{ base: '22px', md: '16px', lg: 0 }}
            >
              {/* <Center w="full" h="full">
                <AnimatePresence exitBeforeEnter>
                  <motion.div
                    style={{ width: '100%', height: '100%' }}
                    key={activeItemIndex}
                    animate={{ opacity: 1, y: 0 }}
                    initial={{ opacity: 0, y: 20 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.2 }}
                  >
                    <AspectRatio
                      minW="full"
                      ratio={16 / 9}
                      borderColor="#CCCCCC"
                      borderWidth="1px"
                      boxShadow="primary"
                      borderRadius="24px"
                      overflow="hidden"
                    >
                      {activeImage && <Image src={activeImage.src} fill alt="" />}
                    </AspectRatio>
                  </motion.div>
                </AnimatePresence>
              </Center> */}
              <Center pt="48px" w="full">
                <AspectRatio
                  minW="full"
                  ratio={600 / 480}
                  // borderColor="#CCCCCC"
                  // borderWidth="1px"
                  // boxShadow="primary"
                  borderRadius="12px"
                  overflow="hidden"
                >
                  <Image src="/img/landing-page-benefit.png" fill alt="" />
                </AspectRatio>
              </Center>
            </Flex>
            <Flex pl="24px" position="relative" h="fit-content">
              <Box w="1px" h="100%" bg="brand.blue40" position="absolute" left={0} />
              <AnimateSharedLayout>
                <Flex
                  w={{ base: 'full', lg: '600px' }}
                  h="full"
                  direction={{ base: 'row', lg: 'column' }}
                  gridColumnGap="8px"
                  gridRowGap={{ base: '12px', md: '12px' }}
                  wrap="wrap"
                  justifyContent="center"
                >
                  {LandingPageCustomerBenefits.map(({ title, text }, index) => (
                    <BenefitsButton
                      key={title}
                      title={title}
                      highlight={text}
                      isSelected={index === activeItemIndex}
                      onClick={() => {
                        setActiveItemIndex(index)
                      }}
                    />
                  ))}
                </Flex>
              </AnimateSharedLayout>
            </Flex>
          </Stack>
        </VStack>
      </Container>
    </Box>
  )
}
