import { Button, Container, Stack, Text, useDisclosure } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { Accordion } from 'components/common/Accordion'
import { ContractorQuestions, CustomerQuestions } from 'components/FaqAccordion'
import { ContactUsFormModal } from './ContactUsFormModal'

export const LandingPageContactForm = ({ isContractor }: { isContractor?: boolean }) => {
  const { query } = useRouter()
  const { isOpen, onClose, onOpen } = useDisclosure({
    defaultIsOpen: Boolean(query.openContactUsModal),
  })

  return (
    <Container id="faq" maxW="container.xl" p={0} centerContent>
      <Stack
        w="full"
        maxW="1000px"
        px="100px"
        py="80px"
        borderRadius="40px"
        backgroundColor="brand.fogGray"
        spacing="48px"
        align="center"
      >
        <Text fontFamily="DM Sans Bold" fontSize="32px" lineHeight="150%">
          Často kladené otázky
        </Text>
        <Accordion
          questions={isContractor ? ContractorQuestions : CustomerQuestions}
          spacing={{ base: '16px', md: '0px' }}
          titleStyles={{ fontSize: '16px' }}
          contentStyles={{ fontSize: '14px' }}
          titlePadding="16px"
          defaultIndex={0}
        />
        <Button variant="link" fontSize="20px" onClick={onOpen}>
          Kontaktujte nás
        </Button>
      </Stack>
      <ContactUsFormModal isOpen={isOpen} onClose={onClose} />
    </Container>
  )
}
