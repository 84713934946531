import { useCallback } from 'react'
import { Box, Button, Flex, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react'
import Autoplay from 'embla-carousel-autoplay'
import useEmblaCarousel from 'embla-carousel-react'
import { createBlogPostPath, SbPost } from 'lib/cms'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { PostPreviewCard } from 'components/storyblok/PostPreviewCard'

interface PostItem {
  content: Omit<SbPost, 'long_text' | 'component'>
  first_published_at: string
  slug: string
}

export const LandingPageBlogPostSlider = ({ postItems }: { postItems: PostItem[] }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ startIndex: 0, active: true, loop: true }, [
    Autoplay({ delay: 4000 }),
  ])
  const scrollPrev = useCallback(() => emblaApi && emblaApi.scrollPrev(), [emblaApi])
  const scrollNext = useCallback(() => emblaApi && emblaApi.scrollNext(), [emblaApi])

  return (
    <VStack px="16px" w="full" align="center" spacing="32px">
      <VStack w="full" align="start" spacing="8px">
        <Text
          maxW="380px"
          fontFamily="Montserrat Semibold"
          fontSize="20px"
          lineHeight="12  0%"
          letterSpacing="-1%"
        >
          Máte ďalšie otázky k portálu, alebo{' '}
          <span
            style={{
              color: '#007FFF',
              fontFamily: 'inherit',
              fontSize: 'inherith',
              letterSpacing: '-1%',
            }}
          >
            hľadáte profesijné rady?
          </span>
        </Text>
        <Text maxW="380px">
          Nájdete tu všetko čo potrebujete vedieť o aplikácii a hodnotný obsah profesijných rád!
        </Text>
      </VStack>

      <HStack w="full" spacing="8px" display={{ base: 'flex', md: 'none' }}>
        <IconChevronLeft stroke={1.5} onClick={scrollPrev} />
        <Box w="full" ref={emblaRef} position="relative" overflow="hidden">
          <Flex w="full" justifyContent="space-between" gap="24px" position="relative">
            {postItems.map((item) => {
              const {
                slug,
                content: { title, image, intro, categories },
              } = item
              return (
                categories?.[0]?.name && (
                  <Link key={title} href={createBlogPostPath(slug)} style={{ minWidth: '100%' }}>
                    <PostPreviewCard
                      thumbnailUrl={image.filename}
                      title={title}
                      intro={intro ?? ''}
                      category={categories?.[0]?.name}
                    />
                  </Link>
                )
              )
            })}
          </Flex>
        </Box>
        <IconChevronRight stroke={1.5} onClick={scrollNext} />
      </HStack>

      <SimpleGrid w="full" columns={3} gap="8px" display={{ base: 'none', md: 'flex' }}>
        {postItems.map((item) => {
          const {
            slug,
            content: { title, image, intro, categories },
          } = item
          return (
            categories?.[0]?.name && (
              <Link key={title} href={createBlogPostPath(slug)} style={{ width: '100%' }}>
                <PostPreviewCard
                  thumbnailUrl={image.filename}
                  title={title}
                  intro={intro ?? ''}
                  category={categories?.[0]?.name}
                />
              </Link>
            )
          )
        })}
      </SimpleGrid>
      <Link href={Route.Blog()}>
        <Button variant="link">Čítať dalšie články</Button>
      </Link>
    </VStack>
  )
}
