import React, { useCallback, useEffect, useState } from 'react'
import {
  AspectRatio,
  Box,
  BoxProps,
  Center,
  Container,
  Flex,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { EmblaCarouselType } from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import Image from 'next/image'
import { Colors } from 'theme/constants'
import { LandingPageCustomerBenefits } from './BenefitsSection'

type UseDotButtonType = {
  selectedIndex: number
  scrollSnaps: number[]
  onDotButtonClick: (index: number) => void
}

export const useEmblaDotButton = (emblaApi: EmblaCarouselType | undefined): UseDotButtonType => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

  const onDotButtonClick = useCallback(
    (index: number) => {
      if (!emblaApi) {
        return
      }
      emblaApi.scrollTo(index)
    },
    [emblaApi],
  )

  const onInit = useCallback((emblaApi: EmblaCarouselType) => {
    setScrollSnaps(emblaApi.scrollSnapList())
  }, [])

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setSelectedIndex(emblaApi.selectedScrollSnap())
  }, [])

  useEffect(() => {
    if (!emblaApi) {
      return
    }

    onInit(emblaApi)
    onSelect(emblaApi)
    emblaApi.on('reInit', onInit).on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onInit, onSelect])

  return {
    selectedIndex,
    scrollSnaps,
    onDotButtonClick,
  }
}

export const BenefitsSectionMobile = ({ ...rest }: BoxProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ slidesToScroll: 'auto', loop: true })

  const { selectedIndex, onDotButtonClick } = useEmblaDotButton(emblaApi)

  return (
    <Box w="full" bg="white" {...rest}>
      <Container maxW="container.xl" p={0} px="16px" centerContent>
        <VStack w="full" align="start" spacing="48px">
          <Text as="h2" fontSize={{ base: '32px', lg: '46px' }} fontFamily="Montserrat Semibold">
            Prečo{' '}
            <span style={{ color: Colors.brand.blue100, fontFamily: 'Montserrat Semibold' }}>
              Zadaj dopyt
            </span>{' '}
            ?{' '}
          </Text>
          <Stack w="full" direction={{ base: 'column', lg: 'row' }} align="center">
            <Center w="full">
              <AspectRatio minW="full" ratio={600 / 480} borderRadius="12px" overflow="hidden">
                <Image src="/img/landing-page-benefit.png" fill alt="" />
              </AspectRatio>
            </Center>
            <Box w="full" pb="48px" ref={emblaRef} overflow="hidden">
              <Flex w="full" justify="space-between" gap="16px">
                {LandingPageCustomerBenefits.map(({ text, title }) => (
                  <Flex minW="full" key={title}>
                    {/* <VStack w="full" h="full" spacing="24px"> */}
                    {/* <AspectRatio
                        w="full"
                        ratio={{ base: 3 / 4, md: 16 / 9 }}
                        borderColor="#CCCCCC"
                        borderWidth="1px"
                        boxShadow="primary"
                        borderRadius="24px"
                        overflow="hidden"
                      >
                        <Image src={src} fill alt="" />
                      </AspectRatio> */}
                    <VStack
                      borderRadius="12px"
                      alignItems="center"
                      position="relative"
                      p="24px"
                      h="fit-content"
                      // maxH={{ base: '280px', md: '186px' }}
                      // cursor="pointer"
                      // bg="white"
                      // borderColor="#CCCCCC"
                      // borderWidth="1px"
                      // boxShadow="primary"
                    >
                      <Text
                        fontFamily="Montserrat Semibold"
                        fontSize={{ base: '14px', md: '20px' }}
                        lineHeight="150%"
                        color="brand.blue100"
                        align="center"
                      >
                        {title}
                      </Text>
                      <Text fontSize={{ base: '14px', md: '16px' }} textAlign="center">
                        {text}
                      </Text>
                    </VStack>
                    {/* </VStack> */}
                  </Flex>
                ))}
              </Flex>
            </Box>
            <Flex w="full" justify="center" gap="16px">
              {LandingPageCustomerBenefits.map((_, index) => (
                <Box
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  w={{ base: '60px', md: '120px' }}
                  h="12px"
                  bg={index === selectedIndex ? 'brand.blue100' : 'gray.200'}
                  borderRadius="12px"
                  onClick={() => onDotButtonClick(index)}
                />
              ))}
            </Flex>
          </Stack>
        </VStack>
      </Container>
    </Box>
  )
}
