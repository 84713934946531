import { Box, Container, Flex, Text } from '@chakra-ui/react'
import { PropsWithChildrenRequired } from 'constants/typings'

interface ContainerWithTagProps {
  title: string
}

export const ContainerWithTag = ({
  children,
  title,
}: PropsWithChildrenRequired<ContainerWithTagProps>) => (
  <Box w="full" bg="#F5F9FE">
    <Container maxW="container.xl" centerContent>
      <Flex w="full" pb={{ base: '32px', lg: '68px' }}>
        <Flex
          px={{ base: '16px', lg: '24px' }}
          py={{ base: '12px', lg: '16px' }}
          bgColor="white"
          borderBottomRadius="8px"
          border="1px #cccccc solid"
          borderTop="none"
        >
          <Text as="h2" fontFamily="DM Sans Bold" fontSize="24px" lineHeight="150%">
            {title}
          </Text>
        </Flex>
      </Flex>
      {children}
    </Container>
  </Box>
)
