import { Button, Container, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { DirectOffer } from 'generated/generated-graphql'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import Link from 'next/link'
import { Colors } from 'theme/constants'
import { Route } from 'constants/common/routes'
import { DirectOfferCard } from 'components/directOffer/DirectOfferCard'
import { DirectOffersSlider } from 'routes/ContractorList/DirectOffersSlider'

export const DirectOffersSection = ({ directOffers }: { directOffers: DirectOffer[] }) => {
  const isOnMobile = useIsOnMobile()

  return (
    <VStack w="full" bg={{ base: '#F5F9FE', lg: 'white' }}>
      <Text
        as="h2"
        fontSize="32px"
        fontFamily="Montserrat Semibold"
        pb="64px"
        display={{ base: 'none', lg: 'block' }}
        lineHeight="150%"
      >
        Nenechajete si ujsť{' '}
        <span
          style={{
            fontFamily: 'Montserrat Semibold',
            fontSize: '32px',
            color: Colors.brand.blue100,
            lineHeight: '150%',
          }}
        >
          Priame ponuky
        </span>
      </Text>

      <Container maxW="container.xl" p={0} px={{ base: '16px', '2xl': 0 }} centerContent>
        <VStack
          w="full"
          // maxW="1196px"
          py="32px"
          px={{ base: '16px', lg: '64px' }}
          spacing="32px"
          bg="#F5F9FE"
          borderRadius="24px"
        >
          <Text
            as="h3"
            fontSize="20px"
            fontFamily="DM Sans"
            textAlign={{ base: 'center', lg: 'left' }}
            maxW={{ base: '500px', lg: 'full' }}
          >
            <strong>Nechce sa vám zadávať dopyt?</strong>{' '}
            <span
              style={{
                fontSize: 'inherit',
                color: Colors.brand.blue100,
              }}
            >
              Potom vyhľadajte čo potrebujete medzi priamymi ponukami!
            </span>
          </Text>
          <VStack spacing={0} maxW={{ base: '500px', lg: 'full' }}>
            <Text
              as="h4"
              fontSize="20px"
              fontFamily="DM Sans Semibold"
              lineHeight="150%"
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Priame ponuky za najlepšie ceny!
            </Text>
            <Text
              as="h4"
              fontSize="16px"
              lineHeight="150%"
              textAlign={{ base: 'center', lg: 'left' }}
            >
              Nájdete tu rôzne akcie a zvýhodnené ponuky formou predvyplnených objednávok a za
              bezkonkurenčné ceny.
            </Text>
          </VStack>
          {isOnMobile ? (
            <DirectOffersSlider directOffers={directOffers} />
          ) : (
            <SimpleGrid minW="full" mt="16px" columns={3} gap="24px">
              {directOffers?.map(({ id, name, unit, unitPrice, validTill, images, condition }) => (
                <DirectOfferCard
                  key={id}
                  id={id}
                  name={name}
                  unit={unit}
                  unitPrice={unitPrice}
                  validTill={validTill}
                  titleImage={images?.[0]}
                  // contractorName={contractor?.companyName}
                  condition={condition}
                  linkTo={Route.Public.DirectOfferDetail({ id })}
                />
              ))}
            </SimpleGrid>
          )}
          <Link href={Route.Public.DirectOffers()}>
            <Button variant="link">Zobraziť viac priamých ponúk</Button>
          </Link>
        </VStack>
      </Container>
    </VStack>
  )
}
