import React from 'react'
import { AspectRatio, Box, Button, Container, Flex, Stack, Text, VStack } from '@chakra-ui/react'
import { createBlogPostPath, generatePlaceholderUrl } from 'lib/cms'
import Image from 'next/image'
import Link from 'next/link'
import { Badge } from './Badge'

export const BlogHeroSection = ({
  slug,
  title,
  category,
  intro,
  thumbnailUrl,
  isFullWidth = false,
  hasReadMore = true,
}: {
  slug: string
  title: string
  category: string
  intro?: string
  thumbnailUrl: string
  isFullWidth?: boolean
  hasReadMore?: boolean
}) => (
  <VStack w="full" spacing="24px">
    <Box w="full">
      <Box position="relative" borderRadius="12px" w="full" maxW="1268px" h="full" maxH="800px">
        <AspectRatio ratio={16 / 9}>
          <Image
            src={thumbnailUrl}
            placeholder="blur"
            blurDataURL={generatePlaceholderUrl(thumbnailUrl)}
            fill
            alt={title}
            style={{ borderRadius: '12px' }}
          />
        </AspectRatio>
      </Box>
    </Box>
    <Container maxW={isFullWidth ? 'full' : 'container.md'}>
      {intro ? (
        <Stack
          w="full"
          direction={{ base: 'column', lg: 'row' }}
          spacing={{ base: '16px', md: '48px' }}
        >
          <VStack w="full" align="start">
            <Text
              fontFamily="DM Sans SemiBold"
              fontSize={{ base: '24px', md: '40px' }}
              lineHeight="150%"
            >
              {title}
            </Text>
            <Badge label={category} />
          </VStack>
          <Flex w="full" h="full" py="12px" alignSelf="center">
            <Text w="full" fontSize={{ base: '14px', md: '20px' }}>
              {intro}
            </Text>
          </Flex>
        </Stack>
      ) : (
        <VStack w="full" align="start">
          <Badge label={category} />
          <Text
            fontFamily="DM Sans SemiBold"
            fontSize={{ base: '24px', md: '40px' }}
            lineHeight="150%"
          >
            {title}
          </Text>
        </VStack>
      )}
    </Container>
    {hasReadMore && (
      <Flex w="full" justify="end" px="24px">
        <Link href={createBlogPostPath(slug)}>
          <Button variant="link">Čítať dalej</Button>
        </Link>
      </Flex>
    )}
  </VStack>
)
