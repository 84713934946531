import React from 'react'
import { Button, Container, Flex, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { createBlogPostPath, SbPost } from 'lib/cms'
import Link from 'next/link'
import { Route } from 'constants/common/routes'
import { BlogHeroSection } from 'components/storyblok/BlogHeroSection'
import { CategoriesWrapper } from 'components/storyblok/CategoriesWrapper'
import { PostPreviewCard } from 'components/storyblok/PostPreviewCard'

interface PostItem {
  content: Omit<SbPost, 'long_text' | 'component'>
  first_published_at: string
  slug: string
}

interface LandingPageBlogSectionProps {
  currentCategory?: string
  postItems: PostItem[]
  headlinePost: SbPost
  isLoading: boolean
  changeCategory: ({ category }: { category: string | undefined }) => void
  categories: {
    items: {
      uuid: string
      name: string
      slug: string
    }[]
  }
}

export const LandingPageBlogSection = ({
  currentCategory,
  postItems,
  headlinePost,
  categories,
  changeCategory,
  isLoading,
}: LandingPageBlogSectionProps) => (
  <Container maxW="container.xl" centerContent mt="128px">
    <VStack w="full " align="start" spacing="64px">
      <VStack w="full" align="start" spacing="16px">
        <Text
          w="640px"
          fontFamily="Montserrat Semibold"
          fontSize="40px"
          lineHeight="150%"
          letterSpacing="-1%"
        >
          Máte ďalšie otázky k portálu, alebo{' '}
          <span
            style={{
              color: '#007FFF',
              fontFamily: 'inherit',
              fontSize: 'inherith',
              letterSpacing: '-1%',
            }}
          >
            hľadáte profesijné rady?
          </span>
        </Text>
        <Text w="380px">
          Nájdete tu všetko čo potrebujete vedieť o aplikácii a hodnotný obsah profesijných rád!
        </Text>
      </VStack>
      <VStack w="full" pt="24px" align="start" spacing="88px">
        <BlogHeroSection
          slug={headlinePost?.slug ?? ''}
          title={headlinePost.title}
          intro={headlinePost.intro}
          thumbnailUrl={headlinePost.image.filename}
          category={headlinePost.categories?.[0]?.name}
          isFullWidth
          hasReadMore
        />
        <CategoriesWrapper
          currentCategory={currentCategory}
          categories={categories}
          onCategorySelection={(selectedCategory) => {
            changeCategory({ category: selectedCategory })
          }}
        />
        <SimpleGrid w="full" columns={3} columnGap="40px" rowGap="64px">
          {postItems.map((item) => {
            const {
              slug,
              content: { title, image, intro, categories },
            } = item
            return (
              categories?.[0]?.name && (
                <Link key={title} href={createBlogPostPath(slug)} style={{ width: '100%' }}>
                  <PostPreviewCard
                    thumbnailUrl={image.filename}
                    title={title}
                    intro={intro ?? ''}
                    category={categories?.[0]?.name}
                  />
                </Link>
              )
            )
          })}
        </SimpleGrid>
        <Flex w="full" justify="center">
          <Link href={Route.Blog()}>
            <Button type="button" px="8" isLoading={isLoading}>
              Pozrieť ďalšie Rady a Tipy
            </Button>
          </Link>
        </Flex>
      </VStack>
    </VStack>
  </Container>
)
