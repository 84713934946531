import { useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { createPublicApolloClient } from 'apollo/client'
import {
  DemandListingTypesDocument,
  DemandListingTypesQuery,
  DirectOffersList,
} from 'generated/generated-graphql'
import { withRedirect } from 'hoc/withRedirect'
import { useIsOnMobile } from 'hooks/useIsOnMobile'
import { getLandingPageLayout } from 'layouts/LandingPageLayout'
import { api, createHeaders, SbPost } from 'lib/cms'
import { BlogPostsQuery, CategoryItems } from 'lib/cms/storyblok-generated/cms'
import { GetStaticProps, InferGetStaticPropsType } from 'next'
import { NextPageWithLayout } from 'types/next'
import { DeepNonNullable } from 'utility-types'
import { BenefitsSection } from 'routes/LandingPage/BenefitsSection/BenefitsSection'
import { BenefitsSectionMobile } from 'routes/LandingPage/BenefitsSection/BenefitsSectionMobile'
import { CreateDemandSection } from 'routes/LandingPage/CreateDemandSection'
import { DemandListPreview } from 'routes/LandingPage/DemandListPreview'
import { DirectOffersSection } from 'routes/LandingPage/DirectOffersSection'
import { HeroSection } from 'routes/LandingPage/HeroSection/HeroSection'
import { LookingForAnimatedText } from 'routes/LandingPage/HeroSection/LookingForAnimatedText'
import { LandingPageBlogPostSlider } from 'routes/LandingPage/LandingPageBlogPostSlider'
import { LandingPageContactForm } from 'routes/LandingPage/LandingPageContactForm'
import { UserTypesSection } from 'routes/LandingPage/UserTypeSection/UserTypesSection'
import { LandingPageBlogSection } from 'routes/Storyblok/LandingPageBlogSection'

interface BlogPostProps {
  directOffers?: DirectOffersList | undefined
  blogPostsData: BlogPostsQuery
  headlinePost: SbPost
  categories: CategoryItems
  listingTypesData: DemandListingTypesQuery['demandListingTypes']
}

const POSTS_PER_PAGE = 3

export const getStaticProps: GetStaticProps<BlogPostProps> = async (context) => {
  const isDraftVersion = process.env.NODE_ENV === 'development' ? true : context.draftMode || false

  const client = createPublicApolloClient()
  const { data: listingTypesData }: { data: DemandListingTypesQuery } = await client.query({
    query: DemandListingTypesDocument,
  })
  // const { data }: { data: DirectOffersList } = await client.query({
  //   query: DirectOffersDocument,
  //   variables: {
  //     input: {
  //       pagination: { limit: 3, offset: 0 },
  //     },
  //   },
  // })
  const blogPostsData = await api.BlogPosts(
    {
      postItemsSortBy: 'first_published_at:desc',
      per_page: POSTS_PER_PAGE,
      page: 1,
    },
    createHeaders({ shouldLoadDraftVersion: isDraftVersion }),
  )
  const blogMeta = await api.BlogMetaData(
    {
      blogItemId: 'blog',
    },
    createHeaders({ shouldLoadDraftVersion: isDraftVersion }),
  )
  return {
    props: {
      // directOffers: data.directOffers ?? undefined,
      listingTypesData: listingTypesData.demandListingTypes,
      blogPostsData,
      headlinePost: {
        ...blogMeta.BlogItem?.content?.headline?.content,
        slug: blogMeta.BlogItem?.content?.headline?.slug,
      },
      categories: blogMeta.CategoryItems as CategoryItems,
      preview: isDraftVersion,
    },
    revalidate: 10,
  }
}

// @ts-ignore
const LandingPage: NextPageWithLayout = ({
  directOffers,
  blogPostsData,
  headlinePost,
  categories,
  listingTypesData,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const isOnMobile = useIsOnMobile()
  const [page, setPage] = useState(2)
  const [currentCategory, setCurrentCategory] = useState('Všetky')
  const [blogPosts, setBlogPosts] = useState(blogPostsData.PostItems?.items ?? [])
  const [isLoading, setIsLoading] = useState(false)
  const fetchBlogPosts = async ({ category = 'Všetky' }: { category?: string }) => {
    setIsLoading(true)
    const data = await api.BlogPosts({
      postItemsSortBy: 'first_published_at:desc',
      per_page: POSTS_PER_PAGE,
      ...(category &&
        category !== 'Všetky' && {
          postItemsFilterQueryV2: { categories: { in_array: [category ?? ''] } },
        }),
      page: category && currentCategory === category ? page : 1,
    })

    setPage((prevState) => prevState + 1)
    setBlogPosts((prevState) => [...prevState, ...(data.PostItems?.items ?? [])])
    setIsLoading(false)
    if (category) {
      setCurrentCategory(category)
    }
  }

  const changeCategory = ({ category }: { category: string | undefined }) => {
    setPage(1)
    setBlogPosts([])
    void fetchBlogPosts({ category })
  }

  return (
    <Flex w="full" direction="column" gap="128px">
      <HeroSection />
      <LookingForAnimatedText />
      {!isOnMobile && <CreateDemandSection listingTypes={listingTypesData} />}
      {isOnMobile ? <BenefitsSectionMobile /> : <BenefitsSection />}
      <Flex w="full" direction="column" gap={{ base: 0, lg: '128px' }}>
        <DemandListPreview />
        {directOffers?.data && <DirectOffersSection directOffers={directOffers?.data} />}
      </Flex>
      <UserTypesSection />
      {!isOnMobile && <LandingPageContactForm />}
      {isOnMobile ? (
        <LandingPageBlogPostSlider postItems={blogPosts as DeepNonNullable<typeof blogPosts>} />
      ) : (
        <LandingPageBlogSection
          postItems={blogPosts as DeepNonNullable<typeof blogPosts>}
          headlinePost={headlinePost}
          categories={categories as DeepNonNullable<typeof categories>}
          changeCategory={changeCategory}
          currentCategory={
            categories.items?.filter((category) => category?.uuid === currentCategory)[0]?.slug ??
            currentCategory
          }
          isLoading={isLoading}
        />
      )}
    </Flex>
  )
}

LandingPage.getLayout = getLandingPageLayout
export default withRedirect()(LandingPage)
