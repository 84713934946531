import React from 'react'
import { AspectRatio, Box, Text, VStack } from '@chakra-ui/react'
import { generatePlaceholderUrl } from 'lib/cms'
import Image from 'next/image'
import { Badge } from './Badge'

export const PostPreviewCard = ({
  title,
  category,
  intro,
  thumbnailUrl,
}: {
  title: string
  category: string
  intro: string
  thumbnailUrl: string
}) => (
  <VStack w="full" maxW="full" spacing="24px">
    <Box
      w="full"
      h="full"
      maxH={{ base: 'auto', md: '226px' }}
      borderRadius="12px"
      overflow="hidden"
    >
      <AspectRatio ratio={{ base: 340 / 170, sm: 396 / 226 }}>
        <Image
          src={thumbnailUrl}
          placeholder="blur"
          blurDataURL={generatePlaceholderUrl(thumbnailUrl)}
          fill
          alt={title}
        />
      </AspectRatio>
    </Box>
    <VStack w="full" spacing="16px" align="start">
      <Text
        fontFamily="DM Sans SemiBold"
        fontSize="20px"
        lineHeight="150%"
        noOfLines={2}
        h={{ base: 'auto', md: '60px' }}
      >
        {title}
      </Text>
      <Badge label={category} />
      <Text fontSize="16px" noOfLines={4}>
        {intro}
      </Text>
    </VStack>
  </VStack>
)
